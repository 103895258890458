import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme,styled } from '@mui/material/styles';
import { TableRow, TableCell, Typography, MenuItem, Link } from '@mui/material';

// components
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { assign } from 'lodash';
import CustomConfirmBox from 'src/components/CustomConfirmBox';

// ----------------------------------------------------------------------

WebhookTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  assignAuditor: PropTypes.func,
  assignAgent: PropTypes.func
};

export default function WebhookTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow, assignAuditor, assignAgent }) {
  const theme = useTheme();

  const { id, name, auditor, channel_type, agent } = row;

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const handDialogBox = () => {
    setOpenDialogBox(!openDialogBox);
  };
  const handleDialogBoxYes = () => {
    onDeleteRow();
  };
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  

  return (
    <>
      <CustomConfirmBox
        open={openDialogBox}
        handleClose={handDialogBox}
        title={'Are you sure you want to delete ?'}
        handleCloseYes={handleDialogBoxYes}
      />
    <TableRow hover selected={selected}>
      <TableCell align="center">
        <Typography variant="subtitle2" noWrap>
          {id}
        </Typography>
      </TableCell>

      <TableCell align="center">{name}</TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {channel_type}
      </TableCell>

      <TableCell align="center">
        {agent.length > 0 ? agent : <Link onClick={() => { assignAgent() }}>Assign an Agent</Link>}
      </TableCell>

      <TableCell align="center">
        {auditor || <Link onClick={() => { assignAuditor() }}>Assign an Auditor</Link>}
      </TableCell>

      <TableCell align="center">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  handDialogBox();
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
    </>
  );
}
