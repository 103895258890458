import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { ApiEndpoints } from '../../../config';
import {
  Box,
  Card,
  Grid,
  MenuItem,
  Stack,
  Typography,
  TextField,
  Button,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Table,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
  RHFUploadSingleFile,
  RHFAutoComplete,
} from '../../../components/hook-form';
import axios from '../../../utils/axios';
import axiosInstance from '../../../utils/axios';

export default function AddNewTemplate({ isEdit, currentUser, fetchTemplates, toggleDrawer }) {
  const { enqueueSnackbar } = useSnackbar();
  const [channelList, setChannelList] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [contentSources, setContentSources] = useState([]);
  const [messageTypes, setMessageTypes] = useState([]);
  const [selectedContentSource, setSelectedContentSource] = useState('');
  const [selectedMessageType, setSelectedMessageType] = useState('');
  const [variables, setVariables] = useState([]);
  const [defaultValue, setDefaultValue] = useState();
  const [isMandatory, setIsMandatory] = useState(false);
  const [imagesFileData, setImagesFileData] = useState('');

  const formSchema = Yup.object().shape({
    templateName: Yup.string().required('Template Name is required'),
    communicationChannel: Yup.string().required('Select communication channel'),
    contentSource: Yup.string().required('Content Source is required'),
    messageType: Yup.string().when('contentSource', {
      is: 'MANUAL',
      then: Yup.string().required('Message Type is required'),
    }),
    templateContent: Yup.string().when('messageType', {
      is: 'TEXT',
      then: Yup.string()
        .required('Template Content cannot be blank')
        .matches(/{{\s*\S.*?\s*}}/, 'Template Content must include non-empty {{}} for variable placeholders'),
    }),
    imageCaption: Yup.string().when('messageType', {
      is: 'IMAGE',
      then: Yup.string().required('Image Caption is required'),
    }),
    file: Yup.mixed().when('messageType', {
      is: (value) => value === 'IMAGE' || value === 'VIDEO',
      then: Yup.mixed()
        .required('A file is required')
        .test(
          'fileSize',
          'File too large',
          (value) => value && value.size <= 1024 * 1024 * 5 // 5MB
        )
        .test(
          'fileType',
          'Unsupported File Format',
          (value) => value && (value.type.includes('image/') || value.type.includes('video/'))
        ),
    }),
  });

  const defaultValues = {
    templateName: isEdit?.name || '',
    communicationChannel: isEdit ? templateData?.department_channel?.[0]?.id : '',
    contentSource: isEdit ? templateData?.message_content_source_type : '',
    messageType: isEdit ? templateData?.message_type : '',
    templateContent: isEdit ? `{{${templateData?.template_content}}}` : '',
    default_value: isEdit ? templateData?.variables?.[0]?.default_value : '',
    file: null,
  };

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;
  const formValues = getValues();
  const values = watch();

  useEffect(() => {
    if (isEdit) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, templateData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiEndpoints.NEWTEMPLATEDROPDOWN);
        const list = response.data?.data?.department_channels;
        const options = list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setChannelList(list);
        setContentSources(response?.data?.data?.content_source);
        setMessageTypes(response?.data?.data?.message_types);
      } catch (err) {
        console.error('Failed to fetch data:', err);
        enqueueSnackbar('Failed to fetch data', { variant: 'error' });
      }
    };
    fetchData();
    fetchTemplateById();
    watch((value, { name, type }) => {
      if (name === 'contentSource') {
        setSelectedContentSource(value.contentSource);
      }
      if (name === 'messageType') {
        setSelectedMessageType(value.messageType);
      }
      if (name === 'templateContent' && value.templateContent) {
        const matches = value.templateContent.match(/{{\s*[\w.]+\s*}}/g);
        const vars = matches ? matches.map((v) => v.slice(2, -2).trim()) : [];
        setVariables(vars);
      }
    });
  }, [watch]);
  const templateContent = watch('templateContent');

  const fetchTemplateById = async () => {
    if (isEdit) {
      const template = await axios.get(ApiEndpoints.GETTEMPLATES + '/' + isEdit?.id);
      setTemplateData(template?.data?.data?.template);
    }
  };

  const handleFileUpload = async (e, fieldName) => {
    const fileList = e;
    // const { name, value } = e.target;
    if (fileList.length > 0) {
      const file = fileList[0];
      let extension = file.name.split('.').pop();
      let fileSize = file.size / 1024 / 1024;
      let extList = ['png', 'svg', 'jpeg', 'jpg', 'gif', 'pdf', 'mp4', 'webm'];
      if (extList.includes(extension)) {
        if (fileSize <= 10) {
          let formData = new FormData();
          formData.append('file', file);
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
          let uploadedResponse = await axiosInstance.post(
            ApiEndpoints.UPLOAD_FILES + `?fileName=${file?.path}`,
            formData,
            config
          );
          if (uploadedResponse?.data?.status == 200) {
            setValue(fieldName, file);
            setImagesFileData(uploadedResponse?.data?.data);
          } else {
            setValue(fieldName, null);
          }
        } else {
          enqueueSnackbar('Size Should be less than 10mb', { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Invalid Extension', { variant: 'error' });
      }
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      caption: data.imageCaption || '',
      department_channel_id: data.communicationChannel,
      name: data.templateName,
      file_name: imagesFileData?.fileName,
      file_url: imagesFileData?.fileUrl,
      source: data.contentSource,
      template_content: data.templateContent.replace(/[{}]/g, ''),
      template_type: data.messageType,
      variable_form_list:
        data.messageType === 'TEXT'
          ? variables.map((variable) => ({
              default_value: defaultValue,
              deleted: false,
              mandatory: isMandatory,
              name: data.templateContent,
            }))
          : [],
    };

    if (data.file && data.file[0]) {
      payload.file = data.file[0];
    }

    try {
      const response = await axios.post(
        isEdit ? ApiEndpoints.UPDATETEMPLATE + `/${isEdit?.id}` : ApiEndpoints.NEWTEMPLATE,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        reset();
        enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!', {
          variant: response.data.status === 200 ? 'success' : 'error',
        });
        toggleDrawer();
        fetchTemplates();
      }
    } catch (error) {
      console.error('Submission error:', error);
      enqueueSnackbar('Failed to submit template', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="h4">{isEdit ? 'Edit Template' : 'Add New Template'}</Typography>
              <RHFTextField name="templateName" label="Template Name" />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Channel</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // multiple
                  name="communicationChannel"
                  value={formValues.communicationChannel || ''}
                  label="Select Channel"
                  error={errors?.communicationChannel?.message}
                  onChange={(event, newValue) => setValue('communicationChannel', event.target.value)}
                >
                  {channelList?.map((channel, i) => (
                    <MenuItem key={i} value={channel?.id}>
                      {channel.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.communicationChannel?.message)}>
                  {errors?.communicationChannel?.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Content Source</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="contentSource"
                  value={formValues.contentSource || ''}
                  label="Select Content Source"
                  error={errors?.contentSource?.message}
                  onChange={(event, newValue) => setValue('contentSource', event.target.value)}
                >
                  {contentSources?.map((type) => (
                  <MenuItem
                    key={type}
                    value={type}
                    sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                  >
                    {type}
                  </MenuItem>
                ))}
                </Select>
                <FormHelperText error={Boolean(errors?.contentSource?.message)}>
                  {errors?.contentSource?.message}
                </FormHelperText>
              </FormControl>
              {formValues?.contentSource === 'MANUAL' && (
                <>
                  <RHFSelect
                    name="messageType"
                    label="Select Message Type"
                    size="large"
                    value={formValues?.messageType}
                    // selectedMessageType={(e) => setSelectedMessageType(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                    sx={{ maxWidth: { md: '100%' } }}
                  >
                    {messageTypes.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  {formValues?.messageType === 'TEXT' && (
                    <>
                      <Controller
                        name="templateContent"
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label="Template Content"
                            helperText="Use {{ }} for variable placeholders"
                            multiline
                          />
                        )}
                      />
                      {templateContent && templateContent.match(/{{\s*\S.*?\s*}}/) && (
                        <Controller
                          name="variables"
                          control={control}
                          render={({ field }) => (
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Variable</TableCell>
                                    <TableCell>Default Value</TableCell>
                                    <TableCell>Mandatory</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {variables.map((variable, idx) => (
                                    <TableRow key={idx}>
                                      <TableCell>{variable}</TableCell>
                                      <TableCell>
                                        <TextField
                                          fullWidth
                                          required
                                          onChange={(e) => setDefaultValue(e.target.value)}
                                          variant="outlined"
                                          size="small"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <Checkbox
                                          checked={variable.isMandatory}
                                          onChange={(e) => setIsMandatory(e.target.checked)}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        />
                      )}
                    </>
                  )}
                  {['IMAGE', 'VIDEO', 'DOCUMENT'].includes(formValues?.messageType) && (
                    <>
                      {formValues?.messageType === 'IMAGE' && <RHFTextField name="imageCaption" label="Image Caption" />}
                      <RHFUploadSingleFile
                        name={'file'}
                        accept={'image/*,video/*,application/pdf'}
                        onDrop={(file) => handleFileUpload(file, 'file')}
                      />
                    </>
                  )}
                </>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                {isEdit ? 'Save Changes' : 'Create Template'}
              </LoadingButton>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

AddNewTemplate.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};
