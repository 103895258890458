import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Grid, Stack, Typography, MenuItem, IconButton, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, RHFSelect, FormProvider } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { is } from 'immutable';
// import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

AddNewWebhook.propTypes = {
    isEdit: PropTypes.object, // Changed to object to properly extract id
    toggleDrawer: PropTypes.func.isRequired,
    getWebhooks: PropTypes.func.isRequired,
};

export default function AddNewWebhook({ isEdit, toggleDrawer, getWebhooks }) {
    const { enqueueSnackbar } = useSnackbar();
    const [webhookData, setWebhookData] = useState([]);


    const newWebhookSchema = Yup.object().shape({
        webhookName: Yup.string().required('Webhook Name is required'),
        url: Yup.string().required('URL is required'),
        description: Yup.string().required('Description is required'),
        methodType: Yup.string().required('Method Type is required'),
        headers: Yup.array().of(
            Yup.object().shape({
                header_key: Yup.string().required('Header key is required'),
                header_value: Yup.string().required('Header value is required')
            })
        ).min(1, 'At least one header is required')
    });

    const defaultValues = {
        webhookName: isEdit?.name || webhookData?.name || '',
        url: isEdit?.url || webhookData?.url || '',
        description: isEdit?.description || webhookData?.description || '',
        methodType: isEdit?.method_type || webhookData?.method || '',
        headers: [{ header_key: '', header_value: '' }]
    };

    const methods = useForm({
        resolver: yupResolver(newWebhookSchema),
        defaultValues,
    });

    const { handleSubmit, formState: { isSubmitting }, control, getValues } = methods;
    const formValues = getValues();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'headers'
    });

    const transformHeaders = (headers) => {
        return Object.keys(headers).map(key => ({
            header_key: key,
            header_value: headers[key]
        }));
    };

    const getWebhook = async () => {
        try {
            const response = await axios.get(`${ApiEndpoints.UPDATEWEBHOOK}/${isEdit.id}`);
            setWebhookData(response?.data?.data?.webhookForm);
            methods.reset({
                webhookName: response?.data?.data?.webhookForm?.name || '',
                url: response?.data?.data?.webhookForm?.url || '',
                description: response?.data?.data?.webhookForm?.description || '',
                methodType: response?.data?.data?.webhookForm?.method || '',
                headers: transformHeaders(response?.data?.data?.webhookForm?.headers) || [{ header_key: '', header_value: '' }]
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (isEdit) {
            getWebhook();
        }

    }, [isEdit, methods]);

    const onSubmit = async (data) => {
        try {
            const payload = {
                description: data.description,
                header_forms: data.headers,
                method_type: data.methodType,
                name: data.webhookName,
                type: 'WEBHOOK',
                url: data.url,
            };

            let response;
            if (isEdit && isEdit.id) {
                response = await axios.post(`${ApiEndpoints.UPDATEWEBHOOK}/${isEdit.id}`, payload);
                enqueueSnackbar('Webhook updated successfully!', { variant: 'success' });
            } else {
                response = await axios.post(ApiEndpoints.NEWWEBHOOK, payload);
                enqueueSnackbar('Webhook added successfully!', { variant: 'success' });
            }

            toggleDrawer();
            getWebhooks();
        } catch (error) {
            enqueueSnackbar(`Failed to ${isEdit ? 'update' : 'add'} Webhook!`, { variant: 'error' });
            console.error('Error:', error);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container width={500}>
                    <Grid item md={12}>
                        <Card sx={{ p: 3 }}>
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                                }}
                            >
                                <Typography variant='h4'>{!isEdit ? 'Add New Webhook' : 'Save Changes'}</Typography>
                                <RHFTextField name="webhookName" label="Webhook Name" />
                                <RHFTextField name="url" label="URL" />
                            <RHFTextField name="description" label="Description" />
                            <Controller
                                name="methodType"
                                control={control}
                                render={({ field }) => (
                                    <RHFSelect
                                        {...field}
                                        label="Select Method Type"
                                        size="large"
                                        InputLabelProps={{ shrink: true }}
                                        SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                        sx={{ maxWidth: { md: '100%' } }}
                                    >
                                        {['GET', 'POST'].map((type) => (
                                            <MenuItem key={type} value={type} sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                )}
                            />

                                <Typography variant='h6'>Http Headers</Typography>
                                {fields.map((field, index) => (
                                    <Box key={field.id} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <RHFTextField name={`headers.${index}.header_key`} label="Header" sx={{ mr: 1 }} />
                                        <RHFTextField name={`headers.${index}.header_value`} label="Value" sx={{ mr: 1 }} />
                                        <IconButton onClick={() => fields.length > 1 && remove(index)}>
                                            {/* <RemoveCircleOutline /> */}
                                            -
                                        </IconButton>
                                    </Box>
                                ))}
                                <LoadingButton
                                    variant="text"
                                    // startIcon={<AddCircleOutline />}
                                    onClick={() => append({ header_key: '', header_value: '' })}
                                >
                                    Add More Header
                                </LoadingButton>
                            </Box>
                            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                    {!isEdit ? 'Create Webhook' : 'Save Changes'}
                                </LoadingButton>
                            </Stack>
                        </Card>
                    </Grid>
            </Grid>
        </FormProvider>
    );
}
