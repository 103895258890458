import PropTypes from 'prop-types';
import * as Yup from 'yup';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, Typography, MenuItem, Divider, Autocomplete, TextField, InputAdornment, IconButton } from '@mui/material';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';
import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField, RHFAutoComplete } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
import axios from '../../../utils/axios';

AddNewOrganization.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
  toggleDrawer: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
};

export default function AddNewOrganization({ isEdit, currentUser, toggleDrawer, isDrawerOpen, fetchOrganizations }) {
  const { enqueueSnackbar } = useSnackbar();
  const IsEditCase = typeof isEdit === 'object';
  const [organizationStatusData, setOrganizationStatusData] = useState();
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });

  const newOrganizationSchema = Yup.object().shape({
    organizationName: Yup.string().required('Organization Name is required'),
    contactPerson: Yup.string().required('Contact Person is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed'),
    contactNumber: Yup.string()
      .required('Mobile number is required')
      .matches(/^[0-9]{10}$/, 'Mobile number must be a 10-digit number'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: !IsEditCase ? Yup.string().required('Password is required') : Yup.string().notRequired(),
    confirmPassword: !IsEditCase ? Yup.string().required('Confirm Password is required') : Yup.string().notRequired(),
    status: Yup.string().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      organizationName: IsEditCase ? isEdit?.name : '',
      contactPerson: IsEditCase ? isEdit?.contact_person : [],
      contactNumber: IsEditCase ? isEdit?.contact_number : '',
      password: IsEditCase ? isEdit?.password : '',
      email: IsEditCase ? isEdit?.email : '',
      status: IsEditCase ? isEdit?.status : '',
      password: '',
      confirmPassword: '',
    }),
    [IsEditCase]
  );

  const methods = useForm({
    resolver: yupResolver(newOrganizationSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isDrawerOpen) {
      localStorage.setItem('newOrganizationFormData', JSON.stringify(values));
    }
  }, [values, isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen) {
      const savedData = localStorage.getItem('newOrganizationFormData');
      console.log("savedData", savedData);
      if (savedData) {
        reset(JSON.parse(savedData));
      }
    }
  }, [isDrawerOpen, reset]);

  // useEffect(() => {
  //   if (IsEditCase) {
  //     reset(defaultValues);
  //   }
  //   if (!IsEditCase) {
  //     reset(defaultValues);
  //   }
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [IsEditCase, defaultValues, reset]);

  const onSubmit = async (data, e) => {
    if (isEqual(data, defaultValues)) {
      enqueueSnackbar('No changes made', { variant: 'info' });
      return;
    }
    const payload = {
      contact_number: data.contactNumber,
      contact_person: data.contactPerson,
      email: data.email,
      name: data.organizationName,
      username: data.contactPerson,
      password: data.password,
      status: data.status,
    };
    const url = IsEditCase
      ? ApiEndpoints.ORGANIZATION + '/update' + '/' + isEdit?.id
      : ApiEndpoints.ORGANIZATION + '/new';
    await axios.post(url, payload)
      .then(() => {
        if (response.data.status === 200) {
          reset();
          enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!', { variant: 'success' });
          fetchOrganizations();
          toggleDrawer();
          localStorage.removeItem('newOrganizationFormData');
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: 'error' });
      });
  };
  const getStatusData = async (data) => {
    await axios.get(ApiEndpoints.ORGANIZATION + '/new').then((response) => {
      if (response.data.status === 200) {
        setOrganizationStatusData(response.data.data);
      }
    }).catch((error) => {
      console.error(error);
      enqueueSnackbar('Failed to fetch!', { variant: 'error' });
    });
  }
  useEffect(() => {
    getStatusData();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container width={500}>
        <Grid item md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="h4">{!isEdit ? 'Add New Organization' : 'Save Changes'}</Typography>
              <RHFTextField name="organizationName" label="Organization Name" />
              <RHFTextField name="contactPerson" label="Contact Person" />
              <RHFTextField name="contactNumber" type="number" label="Contact Number" />
              {/* <RHFSelect
                                name="login_type"
                                label="Login Type"
                                size="large"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                sx={{ maxWidth: { md: '100%' } }}
                            >
                            </RHFSelect> */}
              <RHFTextField name="email" label="Email" />
              {!IsEditCase && <>
                <RHFTextField
                  name="password"
                  label="Password"
                  type={showPassword.password ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}>
                          <Iconify icon={showPassword.password ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <RHFTextField
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })
                          }
                          edge="end"
                        >
                          <Iconify icon={showPassword.confirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </>}
              {/* <RHFSelect
                                name="status"
                                label="Status"
                                size="large"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                sx={{ maxWidth: { md: '100%' } }}
                            >
                                {['Active', 'Inactive'].map((status) => (
                                    <MenuItem key={status} value={status} sx={{ mx: 1, my: 0.5, borderRadius: 0.75, typography: 'body2', textTransform: 'capitalize' }}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </RHFSelect> */}
              <RHFSelect
                name="status"
                label="Status"
                size="large"
                value={values.status}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: '100%' } }}
              >
                <Divider />
                {organizationStatusData?.statusEnums?.map((option, i) => (
                  <MenuItem
                    key={i}
                    value={option}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!isEdit ? 'Create Organization' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}