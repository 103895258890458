import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ApiEndpoints } from '../../../config';
import {
    Box, Card, Grid, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadSingleFile } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import moment from 'moment';
import axiosInstance from '../../../utils/axios';
import { is } from 'immutable';
// import Table from 'src/theme/overrides/Table';

export default function ShowMessageContent({ isEdit, currentUser, messageContent, toggleDrawer }) {
    const [fileType, setFileType] = useState('');
    const [fileUrl, setFileUrl] = useState('');

    const getFile = () => {
        axios.get(ApiEndpoints.TEMPLATES + `/${messageContent?.id}`)
            .then((response) => {
                const url = response?.data?.data?.template?.file_url;
                setFileType(response?.data?.data?.template?.message_type);
                setFileUrl(url);
            })
            .catch((error) => {
                console.error('There was an error!', error);
            });
    }

    useEffect(() => {
        getFile();
    }, []);

    const renderFile = () => {
        switch (fileType) {
            case 'IMAGE':
                return <img src={fileUrl} alt="Image" style={{ width: '100%', height: 'auto' }} />;

            case 'pdf':
                return (
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={fileUrl} />
                    </Worker>
                );

            case 'mp4':
            case 'webm':
            case 'ogg':
                return (
                    <video width="100%" controls>
                        <source src={fileUrl} type={`video/${fileType}`} />
                        Your browser does not support the video tag.
                    </video>
                );

            default:
                return <Typography variant="body2">Unsupported file type</Typography>;
        }
    };

    return (
        <Grid container width={500}>
            <Grid item md={12}>
                <Card sx={{ p: 3 }}>
                    <Box
                        sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                        }}
                    >
                        <Typography variant="h4">Message Content</Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            {messageContent?.message_content}
                        </Typography>
                        {fileUrl && renderFile()}
                    </Box>
                </Card>
            </Grid>
        </Grid>
    );
}