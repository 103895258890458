import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RHFTextField } from '../../../components/hook-form';
import axios from '../../../utils/axios';
import { ApiEndpoints } from '../../../config';
import { PATH_APP } from '../../../routes/paths';

const newAuditorSchema = Yup.object().shape({
    iframeName: Yup.string().required('IFrame Name is required'),
    url: Yup.string().required('URL Name is required'),
    description: Yup.string().required('Description is required'),
    // auditorMobile: Yup.string().required('Mobile number is required'),
});

function AddNewIFrame({ isEdit, currentUser, toggleDrawer, getWebhooks }) {
    const navigate = useNavigate();
    const id = isEdit?.id;
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(newAuditorSchema),
        defaultValues: {
            iframeName: '',
            url: '',
            description: '',
        },
    });

    useEffect(() => {
        if (isEdit) {
            methods.reset({
                iframeName: isEdit.name || '',
                url: isEdit.channel_type || '',
                description: isEdit.description || ''
            });
        }
    }, [isEdit, methods]);

    const getIFrame = async () => {
        try {
            const response = await axios.get(`${ApiEndpoints.UPDATEIFRAME}/${id}`);
            methods.reset({
                iframeName: response?.data?.data?.iframeForm?.name || '',
                url: response?.data?.data?.iframeForm?.url || '',
                description: response?.data?.data?.iframeForm?.description || ''
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (isEdit) {
            getIFrame();
        }
    }, [isEdit]);


    const onSubmit = async data => {
        const payload = {
            description: data.description,
            name: data.iframeName,
            type: 'IFRAME',
            url: data.url,
        };
        try {
            let response;
            if (isEdit) {
                response = await axios.post(`${ApiEndpoints.UPDATEIFRAME}/${id}`, payload);
                enqueueSnackbar('IFrame updated successfully!', { variant: 'success' });
                toggleDrawer();
                getWebhooks();
            } else {
                response = await axios.post(ApiEndpoints.CREATEIFRAME, payload);
                enqueueSnackbar('IFrame added successfully!', { variant: 'success' });
                toggleDrawer();
                getWebhooks();
            }
        } catch (error) {
            enqueueSnackbar(`Failed to ${isEdit ? 'update' : 'add'} IFrame!`, { variant: 'error' });
            console.error('Error:', error);
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container width={500}>
                    <Grid item md={12}>
                        <Card sx={{ p: 3 }}>
                            <Typography variant="h4">{!isEdit ? 'Add New IFrame' : 'Save Changes'}</Typography>
                            <Box
                                sx={{
                                    display: 'grid',
                                    columnGap: 2,
                                    marginTop: 2,
                                    rowGap: 3,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                                }}
                            >
                                <RHFTextField name="iframeName" label="IFrame Name" />
                                <RHFTextField name="url" label="URL" />
                                <RHFTextField name="description" label="Description" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                                <LoadingButton type="submit" variant="contained" loading={methods.formState.isSubmitting}>
                                    {!isEdit ? 'Create IFrame' : 'Save Changes'}
                                </LoadingButton>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </FormProvider >
    );
}

AddNewIFrame.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
};

export default AddNewIFrame;
