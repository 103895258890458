import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

WebhookAPITableToolbar.propTypes = {
    filterName: PropTypes.string,
    filterRole: PropTypes.string,
    onFilterName: PropTypes.func,
    onFilterRole: PropTypes.func,
    optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function WebhookAPITableToolbar({ filterName, filterRole, onFilterName, onFilterRole, optionsRole }) {
    return (
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 1.5, px: 1 }}>
            <TextField
                fullWidth
                size="small"
                value={filterName}
                onChange={(event) => onFilterName(event.target.value)}
                placeholder="Search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    ),
                }}
            />
        </Stack>
    );
}
